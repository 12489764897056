<!-- 登录页 -->
<template>
  <div class="logn">
    <div class="biao">
      <img src="../assets/img/login/biao.png" class="w-full h-full" alt="" />
    </div>
    <!-- 方框 -->
    <div class="kuang">
      <div class="fontone">{{ "登录" }}</div>
      <div class="flex align-items sx">
        <div
          class="cursor_pointer"
          :class="{ fontact: dz == 1 }"
          @click="dz = 1"
        >
          {{ "短信验证码一键登录" }}
        </div>
        <div class="zy">{{ "/" }}</div>
        <div
          class="cursor_pointer"
          :class="{ fontact: dz == 0 }"
          @click="dz = 0"
        >
          {{ "账号登录" }}
        </div>
      
        
      </div>
      <!-- 输入框 -->
      <div v-if="dz == 0">
        <div class="shuru mb23">
          <img src="../assets/img/login/zh.png" class="icos" alt="" />
          <el-input
            v-model.trim="zhanghao"
            placeholder="输入用户名或手机号"
          ></el-input>
        </div>
        <div class="shuru mb36">
          <img src="../assets/img/login/mima.png" class="icos" alt="" />
          <el-input
            v-model.trim="mima"
            show-password
            placeholder="请输入密码"
          ></el-input>
        </div>
      </div>
      <div v-else>
        <div class="shuru mb23">
          <img src="../assets/img/login/shouji.png" class="icos" alt="" />
          <el-input
            v-model.trim="zhanghao"
            placeholder="请输入手机号"
          ></el-input>
        </div>
        <div class="shuru mb36">
          <img src="../assets/img/login/yz.png" class="icos" alt="" />
          <el-input v-model.trim="yanma" placeholder="请输入验证码"></el-input>
          <div>
            <div class="fontseven" v-if="timerNumber == 0" @click="recycle">
              {{ "获取验证码" }}
            </div>
            <div class="fontseven" v-else>{{ timerNumber }}</div>
          </div>
        </div>
      </div>
      <!-- 政策协议 -->
      <div class="flex align-items mb24">
        <el-checkbox v-model="agreement"></el-checkbox>
        <div class="fontTwo ml3" style="padding: 0 0 0 0.3vw">
          已阅读并同意盐都网信通
          <span class="fontThree" @click="xieyi(1)">{{ "《用户协议》" }}</span>
          和
          <span class="fontThree" @click="xieyi(2)">{{ "《隐私政策》" }}</span>
        </div>
      </div>
      <!-- 按钮 -->
      <div class="btn" @click="denglu">{{ "登录" }}</div>
      <!-- 忘记,注册 -->
      <div class="flex align-items space-between fontfour">
        <div class="cursor_pointer one" @click="wangji">{{ "忘记密码?" }}</div>
        <div class="cursor_pointer two" @click="jinzhu">{{ "注册" }}</div>
      </div>
      <!-- 其他方式 -->
      <div class="flex align-items fontfive">
        <div class="flex1 xian"></div>
        <div class="fontsix">{{ "其他方式登录" }}</div>
        <div class="flex1 xian"></div>
      </div>
      <!-- 微信与微薄 -->
      <div class="mt20 flex align-items justify-content">
        <div class="restsImg cursor_pointer mr12" @click="weixin">
          <img
            src="~@/assets/img/one/weixin.png"
            style="width: 100%; height: 100%"
            alt=""
          />
        </div>
        <div class="restsImg cursor_pointer" @click="weibo">
          <img
            src="~@/assets/img/one/weibo.png"
            style="width: 100%; height: 100%"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "../api/modules/login.js";
// token管理工具
import Cookies from "js-cookie";
import bind from "./bind/index.vue";
export default {
  components: {
    bind,
  },
  data() {
    return {
      isyanzhen: 0, //是否发送验证码
      zhanghao: "", //账号
      mima: "", //密码
      yanma: "", //验证码
      agreement: false, //是否阅读协议
      dialogVisible: false, //打开弹窗
      tanNum: 0, //几号弹窗
      tcp: "", //协议内容
      saomaUser: null, //扫码后获得的信息
      timunNum: 0, //0 密码账号登录,1 手机号登录,2 填写手机号和昵称,3 忘记密码
      dz: 1, //0登录,1注册
      timerNumber: 0,
      loading: null,
      aa: false,
    };
  },
  watch: {
    dz: function (vla, old) {
      this.zhanghao = "";
    },
  },
  mounted() {
    this.Scancode();
  },
  methods: {
    // 忘记密码
    async wangji() {
      let aaa = window.localStorage.getItem("sendebang");
      if (aaa != undefined) {
        this.$router.push("/");
        return;
      }
      const routeData = this.$router.resolve({
        path: "/forget",
        query: {},
      });
      window.open(routeData.href, "_blank");
    },
    //进入注册
    async jinzhu() {
      let aaa = window.localStorage.getItem("sendebang");
      if (aaa != undefined) {
        this.$router.push("/");
        return;
      }
      sessionStorage.removeItem("dr");
      const routeData = this.$router.resolve({
        path: "/enroll",
        query: {},
      });

      window.open(routeData.href, "_blank");
      // this.$router.push("/enroll");
    },
    // 计时器倒计时
    countDown() {
      if (this.timerNumber != 0) {
        this.timer = setTimeout(() => {
          this.timerNumber--;
          this.countDown();
        }, 1000);
      }
    },
    // 重新发送验证码
    async recycle() {
      if (this.zhanghao == "") {
        this.$message({
          message: "请输入手机号!",
          type: "warning",
        });
        return;
      }
      this.isyanzhen = 1;
      let res = await API.sms({
        mobile: this.zhanghao, //手机号
        event: "mobilelogin", //'手机验证码登录'
      }).then((res) => {
        if (res.code == 1) {
          this.$message({
            message: "验证码已发送至您的手机,请注意查看!",
            type: "success",
          });
          this.timerNumber = 60;
          this.countDown();
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    // 切换登录与注册
    dezhu(num) {
      this.dz = num;
      if (num == 0) {
        this.timunNum = 0;
      } else {
        this.timunNum = 4;
      }
    },
    // 会员登录时绑定
    async ding() {
      let res = await API.sendChat({
        toid: localStorage.getItem("uid"),
        type: "bind",
        client_id: this.$store.state.client_id,
        data: "",
        port:0
      }).then((res) => {
        // console.log(res, "---");
        if (res.code == 1) {
          // 获取在线状态
          // console.log("已绑定会员");
        }
      });
    },

    // 微信登录
    async weixin() {
      //删除现有的cooking
      // window.localStorage.setItem('sendebang', a)
      //  let aaa = window.localStorage.getItem('sendebang')
      window.localStorage.removeItem("sendebang");
      // Cookies.remove("sendebang");
      // 代表微信登录还是微薄登录
      localStorage.setItem("dr", "wechatpc");
      let res = await API.getAuthorizeUrl({
        platform: "wechatpc",
        kind: "login",
      }).then((res) => {
        if (res.code == 1) {
          // console.log(res, "微信");
          window.location.replace(res.data.url);
        }
      });
    },
    // 微薄登录
    async weibo() {
      // 删除cooking
      // window.localStorage.setItem('sendebang', a)
      //  let aaa = window.localStorage.getItem('sendebang')
      window.localStorage.removeItem("sendebang");
      // Cookies.remove("sendebang");
      // 微薄登录
      localStorage.setItem("dr", "weibo");
      // 获取微薄二维码地址
      let res = await API.getAuthorizeUrl({
        platform: "weibo",
        kind: "login",
      }).then((res) => {
        if (res.code == 1) {
          // 跳转微信扫码地址
          window.location.replace(res.data.url);
        }
      });
    },
    // 获取扫码登录的信息
    async Scancode() {
      // 获取是什么登录的
      let dr = localStorage.getItem("dr");
      // 存放地址里的信息
      let q = {};
      // 代表没有进行扫码登录,这个方法会在,页面刷新时启动
      if (dr == null) {
        return;
      }
      // 获取来自网址连接的信息
      let test = window.location.href;
      test.replace(/([^?&=]+)=([^&]+)/g, (_, k, v) => (q[k] = v));
      // console.log(q);
      //
      if (q.code == undefined) {
        sessionStorage.removeItem("dr");
        return;
      }
      if (dr == "wechatpc") {
        test.replace(/([^?&=]+)=([^&]+)/g, (_, k, v) => (q[k] = v));
        let res = await API.callback({
          code: q.code,
          state: q.state,
          platform: "wechatpc",
        }).then((res) => {
          // console.log(res, "是---");
          window.localStorage.removeItem("dr");
          if (res.code == 1) {
            // 如果没有手机号,先将信息存起来
            this.$store.state.saomaUser = res.data.userinfo;
            // data.下面的code为0 代表新用户
            if (res.data.code == 0) {
              //判断有没有绑定手机号
              // 保存用户凭证
              this.$store.state.cert = res.data.cert;
              // 保存用户昵称
              this.$store.state.openname = res.data.openname;
              localStorage.setItem("openname", res.data.openname);
              // 如果没有手机号跳入绑定页面
              this.$router.push({
                path: "bind",
                query: {
                  cert: res.data.cert,
                  platform: "wechatpc",
                },
              });
              // 进入绑定页面
              // this.timunNum = 2;
              // this.$router.push("/enroll");
              return;
            }
            // 存进cookie
            let a = res.data.userinfo.token;
            // Cookies.set("sendebang", a);
            window.localStorage.setItem("sendebang", a);
            // 拼在地址栏后面进行并跳转
            this.$store.state.loginMessage.paths = "/";
            // 保存自己的id
            localStorage.setItem("uid", res.data.userinfo.user_id);
            //保存过期时间
            localStorage.setItem("guo", res.data.userinfo.expiretime);
            // this.$store.state.loginMessage.uid = res.data.userinfo.user_id
            // 绑定状态
            this.$store.state.path = localStorage.getItem("path");
            this.ding();
            if (this.$store.state.path == null) {
              this.jump("/", this.$store.state.loginMessage);
            } else {
              let a = this.$store.state.path.substr(1);
              this.jumps(a, this.$store.state.loginMessage);
              this.$store.state.path = null;
            }
          }
        });
      } else if (dr == "weibo") {
        test.replace(/([^?&=]+)=([^&]+)/g, (_, k, v) => (q[k] = v));
        let res = await API.callback({
          code: q.code,
          state: q.state,
          platform: "weibo",
        }).then((res) => {
          // console.log(res, "我是微薄");
          window.localStorage.removeItem("dr");
          if (res.code == 1) {
            // 如果没有手机号,先将信息存起来
            this.$store.state.saomaUser = res.data.userinfo;
            // 没有手机号,填写手机号
            if (res.data.code == 0) {
              // this.saoma = true
              this.$store.state.cert = res.data.cert;
              this.$store.state.openname = res.data.openname;
              localStorage.setItem("openname", res.data.openname);
              // this.timunNum = 2;
              this.$router.push({
                path: "bind",
                query: {
                  cert: res.data.cert,
                  platform: "weibo",
                },
              });
              // this.$router.push("/enroll");
              return;
            }
            // 存进cookie
            let a = res.data.userinfo.token;
            // Cookies.set("sendebang", a);
            window.localStorage.setItem("sendebang", a);
            // 拼在地址栏后面进行并跳转
            this.$store.state.loginMessage.paths = "/";
            // 保存自己的id
            localStorage.setItem("uid", res.data.userinfo.user_id);
            //保存过期时间
            localStorage.setItem("guo", res.data.userinfo.expiretime);
            // this.$store.state.loginMessage.uid = res.data.userinfo.user_id
            this.$store.state.path = localStorage.getItem("path");
            // 绑定状态
            this.ding();
            if (this.$store.state.path == null) {
              this.jump("/", this.$store.state.loginMessage);
            } else {
              let a = this.$store.state.path.substr(1);
              this.jumps(a, this.$store.state.loginMessage);
              this.$store.state.path = null;
            }
          }
        });
      }
    },
    // 用户协议
    xieyi(num) {
      // 修改为会话存储
      window.sessionStorage.setItem("xieyi", num);
      const routeData = this.$router.resolve({
        path: "/agreement",
        query: {},
      });
      window.open(routeData.href, "_blank");
      // this.$router.push("/agreement");
    },
    // 多方验证
    yanzheng() {
      if (this.agreement == false) {
        this.$message({
          message: "请阅读用户协议与隐私政策!",
          center: true,
          type: "warning",
        });
        return false;
      }
      if (this.zhanghao == "") {
        this.$message({
          message: "请输入账号",
          center: true,
          type: "warning",
        });
        return false;
      }
      if (this.mima == "") {
        this.$message({
          message: "请输入密码",
          center: true,
          type: "warning",
        });
        return false;
      }
      return true;
    },
    //验证码登录
    async ydenglu() {
      if (this.zhanghao == "") {
        this.$message({
          message: "请输入手机号!",
          type: "warning",
        });
        return;
      }
      if (this.isyanzhen == 0) {
        this.$message({
          message: "尚未发送验证码!",
          type: "warning",
        });
        return;
      }
      if (this.yanma == "") {
        this.$message({
          message: "请输入验证码!",
          type: "warning",
        });
        return;
      }
      if (this.agreement == false) {
        this.$message({
          message: "请阅读用户协议与隐私政策!",
          center: true,
          type: "warning",
        });
        return false;
      }
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await API.mobilelogin({
        mobile: this.zhanghao,
        captcha: this.yanma,
      }).then((res) => {
        if (res.code == 1) {
          if (res.code == 1) {
            // 如果没有手机号,先将信息存起来
            this.$store.state.saomaUser = res.data.userinfo;
            // 没有手机号,进入手机号填写
            if (res.data.code == 0) {
              //判断有没有绑定手机号
              // 将手机号带过去
              // this.$store.state.mobile = this.cellphoneNumber
              // this.$parent.nicheng()
              // this.saoma = true
              // this.cert = res.data.cert
              return;
            }
            // 存进cookie
            let a = res.data.userinfo.token;
            // Cookies.set("sendebang", a);
            window.localStorage.setItem("sendebang", a);
            // 拼在地址栏后面进行并跳转
            this.$store.state.loginMessage.paths = "/";
            // 保存自己的id 需要长时间持有,token失效时删除
            localStorage.setItem("uid", res.data.userinfo.user_id);
            //保存过期时间
            localStorage.setItem("guo", res.data.userinfo.expiretime);
            // this.$store.state.loginMessage.uid = res.data.userinfo.user_id
            this.loading.close();
            this.ding();
            if (this.$store.state.path == null) {
              this.jump("/", this.$store.state.loginMessage);
            } else {
              let a = this.$store.state.path.substr(1);
              this.jumps(a, this.$store.state.loginMessage);
              this.$store.state.path = null;
            }
          }
        } else {
          this.loading.close();
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    // 点击登录按钮
    async denglu() {
      if (this.dz == 1) {
        this.ydenglu();
        return;
      }
      if (this.yanzheng() == false) {
        return;
      }
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await API.login({
        account: this.zhanghao,
        source: "fyrweb",
        password: this.mima,
      }).then((res) => {
        if (res.code == 1) {
          // 存进cookie
          let a = res.data.userinfo.token;
          // Cookies.set("sendebang", a);
          window.localStorage.setItem("sendebang", a);
          // 拼在地址栏后面进行并跳转
          this.$store.state.loginMessage.paths = "/";
          // 保存自己的id
          localStorage.setItem("uid", res.data.userinfo.user_id);
          //保存过期时间
          localStorage.setItem("guo", res.data.userinfo.expiretime);
          // this.$store.state.loginMessage.uid = res.data.userinfo.user_id
          this.$store.state.path = localStorage.getItem("path");
          this.loading.close();
          // 绑定状态
          this.ding();
          if (this.$store.state.path == null) {
            this.jump(
              this.$store.state.loginMessage.paths,
              this.$store.state.loginMessage
            );
          } else {
            let a = this.$store.state.path.substr(1);
            if (a == "other") {
              a = "/";
              this.$store.state.loginMessage.id = "";
            }
            this.jumps(a, this.$store.state.loginMessage);
            this.$store.state.path = null;
          }
        } else {
          this.loading.close();
          this.$message({
            message: res.msg,
            center: true,
            type: "warning",
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.logn {
  width: 100vw;
  min-width: 1200px;
  height: 100vh;
  min-height: 800px;
  background-image: url("../assets/img/login/bgt.jpg");
  background-size: 102% 100%;
  background-position: 0px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  // padding: 3% 0 0 0;
}
.biao {
  width: 40vw;
  height: 15vh;
  position: absolute;
  left: 7.4%;
  top: 10vw;
  z-index: 10;
}
.kuang {
  min-width: 476px;
  min-height: 656.306px;
  width: 31vw;
  //   height: 61.7%;
  background: #ffffff;
  box-shadow: inset -4px 0 16px 0 rgba(255, 255, 255, 0.32);
  border-radius: 20px;
  position: absolute;
  left: 59.9%;
  // top: 50%;
  padding: 7vh 3vw 6vh 5vw;
  box-sizing: border-box;
}
.sx {
  margin-top: 3vh;
  margin-bottom: 2vh;
  font-family: PingFangSC-Medium;
  font-size: 1vw;
  color: #4b4b4b;
  letter-spacing: 0;
  font-weight: 500;
}
.zy {
  margin-left: 0.7vw;
  margin-right: 0.7vw;
}
.shuru {
  width: 100%;
  height: 6vh;
  background: #f1f1f1;
  border-radius: 2px;
  display: flex;
  align-items: center;
  padding: 0 1vw;
  box-sizing: border-box;
}
.icos {
  width: 1.7vw;
  height: 1.7vw;
}
::v-deep(.el-input__inner) {
  background: #f1f1f1 !important;
  border: 0px solid !important;
  color: #3f4347;
  font-size: 1vw;
  line-height: 0;
  height: 6vh;
  &::placeholder {
    font-family: PingFangSC-Regular;
    font-size: 1.1vw;
    color: #3f4347;
    letter-spacing: 0;
    font-weight: 400;
  }
}
.btn {
  width: 100%;
  height: 6vh;
  text-align: center;
  line-height: 6vh;
  background: #2c74c6;
  border-radius: 2px;
  cursor: pointer;
  font-family: PingFangSC-Medium;
  font-size: 1.5vw;
  color: #ffffff;
  letter-spacing: 0;
  font-weight: 500;
  margin-top: 2.5vh;
  margin-bottom: 2.5vh;
}
.mb23 {
  margin-bottom: 2.9vh;
}
.mb36 {
  margin-bottom: 4.2vh;
}
.mt20 {
  margin-top: 2.5vh;
}
.fontone {
  font-family: PingFangSC-Medium;
  //   font-size: 38px;
  font-size: 2.3vw;
  color: #131313;
  letter-spacing: 0;
  font-weight: 700;
}
.fontTwo {
  font-family: PingFangSC-Regular;
  font-size: 0.9vw;
  color: #727272;
  letter-spacing: 0;
  font-weight: 400;
  white-space: nowrap;
}
.fontThree {
  font-family: PingFangSC-Regular;
  font-size: 0.9vw;
  color: #0754a6;
  letter-spacing: 0;
  font-weight: 400;
  cursor: pointer;
}
.fontfour {
  padding: 0 0.8vw;
  box-sizing: border-box;
  font-family: PingFangSC-Regular;
  font-size: 1vw;
  color: #0754a6;
  letter-spacing: 0;
  font-weight: 400;
  margin-bottom: 6.8vh;
  .one:hover {
    color: #ea5e5b;
  }
  .two:hover {
    color: #ea5e5b;
  }
}
.fontfive {
  padding: 0 0.8vw;
  box-sizing: border-box;
}
.fontsix {
  font-family: PingFangSC-Medium;
  font-size: 1vw;
  color: #4b4b4b;
  letter-spacing: 0;
  font-weight: 500;
  margin-left: 0.5vw;
  margin-right: 0.5vw;
}
.fontseven {
  font-family: PingFangSC-Regular;
  font-size: 1.2vw;
  color: #2c74c6;
  letter-spacing: 0;
  font-weight: 400;
  white-space: nowrap;
  cursor: pointer;
}
.xian {
  height: 1px;
  border: 1px solid #a6a6a6;
}
.restsImg {
  width: 3.4vw;
  height: 3.4vw;
}
.mr12 {
  margin-right: 2.5vw;
}
.fontact {
  color: #2670c5;
  font-weight: 700;
}
::v-deep(.el-checkbox__inner) {
  border: 1px solid #333;
}
</style>