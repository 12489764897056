<!-- 绑定页面 -->
<template>
  <div class="_cellphone">
    <div class="bese"></div>
    <div class="dingbu"></div>
    <div class="banxin">
      <!-- 板块 -->
      <div class="card">
        <div class="fontone">
          {{ `绑定${shenme}` }}
        </div>
        <div>
          <!-- 绑定 -->
          <div>
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="126px"
              class="demo-ruleForm"
            >
              <!-- 手机验证码 -->
              <el-form-item label="手机号：" prop="dianhua">
                <div class="yanzh">
                  <el-input
                    class="inline-input"
                    v-model="ruleForm.dianhua"
                    placeholder="请输入手机号"
                  >
                  </el-input>
                  <div class="flex1">
                    <div
                      v-if="timerNumber == 0"
                      class="fonttwo"
                      @click="SendingNumber"
                    >
                      {{ "获取验证码" }}
                    </div>
                    <div v-else class="fonttwo">{{ timerNumber }}</div>
                  </div>
                </div>
              </el-form-item>
              <!-- 验证码 -->
              <el-form-item label="验证码：" prop="yanzhneg">
                <el-input
                  class="inline-input"
                  v-model="ruleForm.yanzhneg"
                  placeholder="请输入验证码"
                >
                </el-input>
              </el-form-item>
            </el-form>
          </div>

          <div class="anniu">
            <div class="btn" @click="zhuce('ruleForm')">{{ "提交" }}</div>
          </div>
          <div class="fanhui" @click="fanhui">{{ "尚未注册？点此注册" }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import API from "@/api/modules/login.js";
import Cookies from "js-cookie";
var timer; // 计时器
export default {
  data() {
    return {
      loading: null,
      shenme: "",
      rules: {
        yanzhneg: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
        dianhua: [
          { required: true, message: "请填写联系人电话", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
      },
      ruleForm: {
        dianhua: "", //手机号
        yanzhneg: "", //验证码
      },
      timerNumber: 0,
      cert: "", //扫码凭证
      platform: "", //什么平台
      qq: {},
    };
  },
  mounted() {
    let q = {};
    // 获取来自网址连接的信息
    let test = window.location.href;
    test.replace(/([^?&=]+)=([^&]+)/g, (_, k, v) => (q[k] = v));
    this.qq = q;
    this.cert = q.cert;
    this.platform = q.platform;
    if (this.platform == "wechatpc") {
      this.shenme = "微信";
    } else {
      this.shenme = "微博";
    }
  },
  methods: {
    // 会员登录时绑定
    async ding() {
      let res = await API.sendChat({
        toid: localStorage.getItem("uid"),
        type: "bind",
        client_id: this.$store.state.client_id,
        data: "",
        port:0
      }).then((res) => {
        // console.log(res, "---");
        if (res.code == 1) {
          // 获取在线状态
          // console.log("已绑定会员");
        }
      });
    },
    fanhui() {
      this.$router.push({
        path: "enroll",
        query: this.qq,
      });
    },
    zhuce(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.zhu();
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    async zhu() {
      this.loading = this.$loading({
        lock: true,
        // text: "Loading",
        // spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.0)",
      });
      let res = await API.bind({
        mobile: this.ruleForm.dianhua,
        captcha: this.ruleForm.yanzhneg,
        cert: this.cert,
        platform: this.platform,
      }).then((res) => {
        if (res.code == 0) {
          this.loading.close();
          this.fanhui();
          return;
        }
        if (res.code == 1) {
          this.loading.close();
          // 存进cookie
          let a = res.data.userinfo.token;
          // Cookies.set("sendebang", a);
          window.localStorage.setItem("sendebang", a);
          //  let aaa = window.localStorage.getItem('sendebang')
          //  window.localStorage.removeItem('sendebang')
          // 拼在地址栏后面进行并跳转
          this.$store.state.loginMessage.paths = "/";
          // 保存自己的id
          localStorage.setItem("uid", res.data.userinfo.user_id);
          //保存过期时间
          localStorage.setItem("guo", res.data.userinfo.expiretime);
          // this.$store.state.loginMessage.uid = res.data.userinfo.user_id
          this.$store.state.path = localStorage.getItem("path");
          // 绑定状态
          this.ding();
          if (this.$store.state.path == null) {
            this.jump("/", this.$store.state.loginMessage);
          } else {
            let a = this.$store.state.path.substr(1);
            this.jumps(a, this.$store.state.loginMessage);
            this.$store.state.path = null;
          }
        }
      });
    },
    async SendingNumber() {
      if (!/^1[3456789]\d{9}$/.test(this.ruleForm.dianhua)) {
        this.$message({
          message: "您的手机号码输入有误,请您重新输入!",
          type: "warning",
        });
        return;
      }
      if (this.ruleForm.dianhua == "") {
        this.$message({
          message: "请输入手机号!",
          type: "warning",
        });
        return;
      }
      this.loading = this.$loading({
        lock: true,
        // text: "Loading",
        // spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.0)",
      });
      let res = await API.sms({
        mobile: this.ruleForm.dianhua, //手机号
        event: "bind", //'手机验证码登录'
      }).then((res) => {
        if (res.code == 1) {
          this.$message({
            message: "验证码已发送至您的手机,请注意查看!",
            type: "success",
          });
          this.timerNumber = 60;
          this.countDown();
          this.loading.close();
        } else {
          this.loading.close();
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    // 计时器倒计时
    countDown() {
      if (this.timerNumber != 0) {
        this.timer = setTimeout(() => {
          this.timerNumber--;
          this.countDown();
        }, 1000);
      }
    },
  },
  beforeDestroy() {},
};
</script>
  
  <style scoped lang="scss">
.bese {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #eff5ff !important;
  z-index: -1;
}
._cellphone {
  width: 100vw;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.dingbu {
  width: 100%;
  height: 127px;
  background: url("../../assets/img/login/ding.png");
  background-size: 100% 100%;
}
.banxin {
  width: 1200px;
  margin-top: 60px;
  display: flex;
  justify-content: center;
}
.card {
  width: 660px;
  background: #ffffff;
  box-shadow: 3px 8px 11px 0 rgba(49, 60, 87, 0.12);
  border-radius: 20px;
  padding: 29.03px 101px 17px 69px;
  box-sizing: border-box;
  margin-bottom: 91px;
}
.demo-ruleForm {
  width: 100%;
}
::v-deep(.el-form-item__label) {
  font-family: PingFangSC-Regular;
  font-size: 18px !important;
  color: #191a1c;
  letter-spacing: 0;
  font-weight: 400 !important;
  white-space: nowrap !important;
}
::v-deep(.el-input__inner) {
  width: 100% !important;
  height: 48px !important;
  background: #f1f1f1 !important;
  border-radius: 2px !important;
  border: 0px solid;
  font-family: PingFangSC-Regular;
  font-size: 18px !important;
  color: #191a1c !important;
  letter-spacing: 0;
  font-weight: 400;
  &::placeholder {
    font-family: PingFangSC-Regular;
    font-size: 18px !important;
    color: #484c51 !important;
    letter-spacing: 0;
    font-weight: 400 !important;
  }
}
.yanzh {
  display: flex;
  align-items: center;
  flex: 1;
  background: #f1f1f1 !important;
  border-radius: 2px !important;
  padding: 0 16px 0 0;
  box-sizing: border-box;
}
.fontone {
  font-family: PingFangSC-Medium;
  font-size: 38px;
  color: #131313;
  letter-spacing: 0;
  text-align: center;
  font-weight: 500;
  margin-bottom: 31.76px;
}
.anniu {
  margin-top: 54px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 46.52px;
}
.btn {
  width: 327px;
  height: 48px;
  background: #2c74c6;
  border-radius: 2px;
  text-align: center;
  line-height: 48px;
  font-family: PingFangSC-Medium;
  font-size: 20px;
  color: #ffffff;
  letter-spacing: 0;
  font-weight: 500;
  cursor: pointer;
}
.fonttwo {
  font-family: PingFangSC-Regular;
  font-size: 18px;
  color: #2c74c6;
  letter-spacing: 0;
  text-align: right;
  font-weight: 400;
  white-space: nowrap;
  cursor: pointer;
}
.fanhui {
  font-family: PingFangSC-Regular;
  font-size: 15px;
  color: #296aef;
  letter-spacing: 0;
  text-align: center;
  font-weight: 400;
  margin-bottom: 17px;
  cursor: pointer;
}
</style>